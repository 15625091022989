import { Link } from 'gatsby';
import * as React from 'react';

import PackingMaterials from './packing-materials-table';

const ContainersFirstSection = () => (
  <article className="flex flex-wrap max-w-6xl px-6 py-4 mx-auto mt-20">
    <div className="w-full md:w-1/3">
      <h2 className="text-5xl font-semibold leading-none uppercase heading font-display">
        We sell <br />
        boxes too!
      </h2>
    </div>
    <div className="w-full mt-6 md:mt-0 md:w-2/3">
      <div className="rich-text">
        <h2>We sell packing materials in-house</h2>
        <p>
          Below we have the packing materials that we sell at{' '}
          <Link to="https://www.frontlineremovals.com.au/">
            Frontline Removals
          </Link>
          . You can purchase these packing materials standalone or in the moving
          quote for your move with us.
        </p>
      </div>
      <PackingMaterials />
    </div>
  </article>
);

export default ContainersFirstSection;
