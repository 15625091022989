import * as React from 'react';

import Items from './items';
import Table from './table';

const PackingMaterials = () => (
  <Table title="Packing materials">
    <Items />
  </Table>
);

export default PackingMaterials;
