/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';

const Items = () => (
  <table className="relative w-full rich-text">
    <thead className="sticky top-0 shadow-md bg-brand-blue">
      <tr>
        <th className="text-left">Item</th>
        <th className="text-right">Price (each)</th>
        <th className="text-right">Bulk</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th className="font-normal text-left">Book/wine cartons</th>
        <td className="text-right">$3.50</td>
        <td className="text-right">$30.00 (10pk)</td>
      </tr>
      <tr>
        <th className="font-normal text-left">Tea chest cartons</th>
        <td className="text-right">$5.50</td>
        <td className="text-right">$45.00 (10pk)</td>
      </tr>
      <tr>
        <th className="font-normal text-left">Book/wine cartons 2nd hand</th>
        <td className="text-right">$3.50</td>
        <td className="text-right">$30.00 (10pk)</td>
      </tr>
      <tr>
        <th className="font-normal text-left">Tea chest cartons 2nd hand</th>
        <td className="text-right">$3.50</td>
        <td className="text-right">$30.00 (10pk)</td>
      </tr>
      <tr>
        <th className="font-normal text-left">
          Porta-Robes (for hanging clothes)
        </th>
        <td className="text-right">$17.50</td>
        <td className="text-right" />
      </tr>
      <tr>
        <th className="font-normal text-left">TV Carton</th>
        <td className="text-right">Call for price</td>
        <td className="text-right" />
      </tr>
      <tr>
        <th className="font-normal text-left">Bicycle Carton</th>
        <td className="text-right">$20.50</td>
        <td className="text-right" />
      </tr>
      <tr>
        <th className="font-normal text-left">Tape</th>
        <td className="text-right">$3.50</td>
        <td className="text-right">$18 (6pk)</td>
      </tr>
      <tr>
        <th className="font-normal text-left">
          Bubble wrap (per linear metre)
        </th>
        <td className="text-right">$3.00</td>
        <td className="text-right" />
      </tr>
      <tr>
        <th className="font-normal text-left">Large wrapping paper</th>
        <td className="text-right">7.5kg $25</td>
        <td className="text-right">$15kg $50</td>
      </tr>
      <tr>
        <th className="font-normal text-left">
          Mattress protectors in all sizes
        </th>
        <td className="text-right">$6.00</td>
        <td className="text-right" />
      </tr>
      <tr />
      <tr>
        <th className="font-normal text-left">Sofa/lounge protectors</th>
        <td className="text-right">$5.00</td>
        <td className="text-right" />
      </tr>
      <tr>
        <th className="font-normal text-left">Dining chair covers</th>
        <td className="text-right">$2.00</td>
        <td className="text-right" />
      </tr>
    </tbody>
  </table>
);

export default Items;
